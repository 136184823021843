@import '../../styles/colors.scss';
@import '../../styles/override.scss';

.App {
  background: $bg-color;
  color: $primary-color;
  font-size: 15px;
  font-family: 'Source Code Pro', monospace;
  line-height: 21px;
  height: 100%;
  width: 100%;

  &-Content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
  }

  &_theme_white {
    background: #EDEFF1;
    color: #212121;
    font-size: 15px;
    font-family: 'Source Code Pro', monospace;
    line-height: 21px;
    height: 100%;
    min-height: 100vh;
    width: 100%; 
  }
}
