@import '../../styles/colors.scss';

.Experience {
  text-align: justify;

  &-Title {
    color: $primary-color;
    font-size: 1.3rem;
    margin-bottom: 24px;
    text-decoration: underline;
  }

  &-Item {
    color: $text-color;
    margin-bottom: 24px;
  }

  &-ItemTitleWrapper {
    display: flex;
  }
  
  &-ItemTitle {
    font-size: 1.3rem;
    margin-bottom: 0;
  }
  
  &-ItemSubtitle {
    font-size: 0.6rem;
  }

  &-ItemText {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 570px) {
  .Experience {
    margin: 20px;
  }
}
