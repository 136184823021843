@import '../../styles/colors.scss';

.Avatar {
  text-align: center;
  width: 100%;

  &-Image {
    border: 3px solid rgba($primary-color,.15);
    border-radius: 50%;
    height: 300px;
    margin: 32px auto 0;
    max-width: 300px;
    min-height: 250px;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
  }

  &-Username {
    color: $text-color;
    display: inline-block;
    margin-bottom: 0.3rem;
    text-transform: capitalize;
  }

  &-Contact {
    color: $text-color;
    display: flex;
    font-size: 0.9rem;
    justify-content: center;
    margin-bottom: 0;
  }
}
