@import "../../styles/colors.scss";
@import "../../styles/override.scss";

.Achievements {
  margin-bottom: 20px;
  
  &-Title {
    font-size: 1.4rem;
    text-decoration: underline;
  }
  
  &-List {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  &-ItemLink {
    color: $text-color;
  }

  &-Icon {
    margin: 8px;
    margin-bottom: 4px;
    margin-left: 0;
    height: 32px;
    width: 32px;
  }
}

@media screen and (max-width: 570px) {
  .Achievements {
    margin: 20px;
  }
}
