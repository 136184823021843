@import '../../styles/colors.scss';

.Summary {
  margin-bottom: 25px;

  &-Title {
    color: $primary-color;
    font-size: 1.4rem;
    text-decoration: underline;
  }

  &-Content {
    color: $text-color;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 570px) {
  .Summary {
    margin: 20px;
  }
}
