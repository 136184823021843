.CopyWrapper {
  position: relative;

  &-Icon {
    cursor: pointer;
    display: none;
    font-size: 10px;
    right: -6px;
    top: -6px;
    position: absolute;
  }

  &:hover {
    .CopyWrapper-Icon {
      display: block;
    }
  }
}
