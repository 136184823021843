@import '../../styles/colors.scss';

.Skills {
  margin-bottom: 25px;
  width: 100%;

  &-List {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &-Item {
    background: $primary-color;
    border-radius: 12px;
    color: $bg-color;
    margin: 0 4px 4px 0;
    padding: 2px 8px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 570px) {
  .Skills {
    margin-bottom: 20px;
  }
}
