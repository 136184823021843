@import './colors.scss';

p {
  margin: 0 0 0.5em 0;
}

.tooltip {
  color: $primary-color;
}

.timeline .timeline-item .timeline-icon::before {
  border-color: $primary-color;
}

.tile {
  width: 100%;
}
