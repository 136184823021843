@import '../../styles/colors.scss';

.Socials {
  display: flex;
  flex-direction: column;
  right: 20px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);

  &-Item, &-Item:visited {
    font-size: 24px;
    color: $primary-color;
    cursor: pointer;
    margin: 10px 0;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: darken($primary-color, 10%);
    }
  }
}

@media screen and (max-width: 570px) {
  .Socials {
    flex-direction: row;
    left: 50%;
    justify-content: space-around;
    top: 99%;
    transform: translate(-50%, -99%);
  }
}
