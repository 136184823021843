.Education {
  margin-bottom: 30px;

  &-Title {
    font-size: 1.4rem;
    text-decoration: underline;
  }

  &-Item {
    margin-bottom: 20px;
  }

  &-ItemTitle {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 570px) {
  .Education {
    margin: 20px;
  }
}
